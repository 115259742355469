import React from "react";
import { PageLayout, TrustedBy } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { StaticImage } from "gatsby-plugin-image";
import ImageAndBulletPoints from "@components/ImageAndBulletPoints/ImageAndBulletPoints";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import {
	PinkCircles0,
	PinkCircles1,
	PinkSquares0,
	PinkSquares1,
} from "@assets/Background";
import LearnMoreSection from "@components/LearnMoreSection/LearnMoreSection";
import { pinkText } from "@components/Typography/Typography";
import { GetADemo } from "@components/GetADemo/GetADemo";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import * as styles from "../../CommonResourcesOverview.module.scss";

export default function LiveEditing() {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "live-editing" }}
		>
			<DemoSection />
			<RealtimeEditor />
			<GenemodNotebook />
			<PoweredByGenemod />
			<TrustedBy />
			<LearnMoreSection textColor="text-dark" />
		</PageLayout>
	);
}

function DemoSection() {
	return (
		<BackgroundFadeContainer
			type="purpleBlueFade"
			skewBottom
			withHeaderPadding
		>
			<GetADemo
				titleText={
					"Build your best ideas together, in Genemod Notebook"
				}
				subtitleText={
					"The only electronic lab notebook where you can create and collaborate on experiments in real-time. Seamless collaboration, from anywhere."
				}
				imageNode={
					<StaticImage
						loading="eager"
						src="../../../assets/products/co-editing_snippet.png"
						alt="co-editing-snippet"
					/>
				}
				textColor="ui-01"
				linkColor="ui_01"
			/>
		</BackgroundFadeContainer>
	);
}
function RealtimeEditor() {
	return (
		<BackgroundFadeContainer type={"pinkFade"} skewTop skewBottom>
			<BackgroundShapes
				leftShape={{
					shape: <PinkSquares0 />,
					yOffset: 110,
					xOffset: -50,
				}}
				rightShape={{
					shape: <PinkSquares1 />,
					yOffset: 280,
					xOffset: 200,
				}}
			>
				<SectionTitle
					title={pinkText`“Powered by Genemod” lets you collaborate ${"in real-time"}, without hassle`}
					subtitle="Genemod is the only visual lab automation platform that supports real-time editing for multiple researchers at once."
					textColor="text-dark"
					marginBottom={12}
					marginBottomTablet={12}
					marginBottomMobile={12}
					titleWidth={950}
					subtitleWidth={686}
				/>
				<StaticImage
					loading="eager"
					src={"../../../assets/products/exp_editor_large.png"}
					alt="Consumable-space"
					width={996}
					height={608}
					className={styles.fullWidthImage}
				/>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}

function GenemodNotebook() {
	return (
		<BackgroundFadeContainer type="blueFade" skewTop skewBottom>
			<SectionTitle
				title={pinkText`Genemod Notebook takes R&D collaboration to a ${"whole other level"}`}
				subtitle="Create flexible and editable protocol templates, add attachments to experiments, and build Excel-like tables within entries."
				textColor="ui-01"
				marginBottom={30}
				marginBottomTablet={60}
				titleWidth={960}
				subtitleWidth={682}
			/>
			<ImageAndBulletPoints
				width={966}
				bullets={[
					{
						title: "Unlock better insights",
						text: "In the last few years, every team has undergone a digital transformation, and every team is accumulating more and more data. Researchers need access to the latest experimental, assay/screening data, and inventory data from a variety of sources.",
					},
					{
						title: "Boost productivity",
						text: pinkText`Providing scientists access to all data ${"from a single interface"} allows for a seamless workflow and removes the need to switch between applications.`,
					},
				]}
				image={
					<StaticImage
						loading="eager"
						src="../../../assets/products/ROI-graph.png"
						alt="ROI Graph"
						width={441}
						height={477}
					/>
				}
				bulletStyles={{
					titleVariant: "HEADER4",
					titleColor: "ui-01",
					textVariant: "SUBHEADERDESC",
					textColor: "ui-01",
					containerWidth: 496,
				}}
			/>
		</BackgroundFadeContainer>
	);
}

function PoweredByGenemod() {
	return (
		<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
			<BackgroundShapes
				leftShape={{
					shape: <PinkCircles0 />,
					xOffset: -40,
				}}
				rightShape={{
					shape: <PinkCircles1 />,
					yOffset: 280,
					xOffset: 77,
				}}
			>
				<SectionTitle
					title={"How does “Powered by Genemod” work?"}
					subtitle={
						"Genemod enables large institutions with multiple teams to centralize projects and experiments on one platform. Google Docs isn’t built for life science R&D - so we built a platform that is."
					}
					textColor="text-dark"
					marginBottom={12}
					marginBottomTablet={12}
					marginBottomMobile={12}
					titleWidth={882}
					subtitleWidth={872}
				/>
				<StaticImage
					loading="eager"
					src={"../../../assets/products/switch-workspace-large.png"}
					alt="Dashboard"
					width={996}
					height={608}
					className={styles.fullWidthImage}
				/>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}
